import compact from "lodash/compact"

import { stringCompare } from "./string"
import { makeSlug } from "./slug"

function getLogo(Logo, LinkedinProfile, Crunchbase) {
  const logo =
    Logo || LinkedinProfile?.[0]?.data.Logo || Crunchbase?.[0]?.data.Logo
  const logoSharp = logo?.localFiles?.[0]?.childImageSharp
  return logoSharp?.resize || logoSharp?.fuild || logoSharp?.fixed
}

function transformCategory(category) {
  if (!category?.data) return undefined

  // const {
  //   id,
  //   data: { Name, Count, Cover, Parent },
  // } = category

  const { data: {cat_name, imageURLs} } = category

  // const parent = transformCategory(Parent?.[0])
  // const cover = Cover?.localFiles?.[0]?.childImageSharp
  // const cover = Image[0].thumbnails.large.url
  const cover = imageURLs
  //.thumbnails.large.url

  return {
    // id,
    name: cat_name,
    // fullName: compact([parent?.name, Name]).join(" > "),
    // count: Count,
    cover: cover,
    slug: `/categories/${makeSlug(cat_name)}`,
    // parent,
  }
}

export function transformCategories(rawCategories) {
  const categories = rawCategories
    .map(transformCategory)
    .sort((a, b) => stringCompare(a.name, b.name))

  if (typeof window === "object") {
    // eslint-disable-next-line no-restricted-globals
    window.categories = categories
    // eslint-disable-next-line no-console
    // console.log({ categories })
  }

  return categories
}

function transformThumbnails(Photos) {
  return Photos?.internal
    ? JSON.parse(Photos.internal.content).map(
        internal => internal.thumbnails?.large
      )
    : []
}

const DescriptionRegexp = /([^.]{2}\.)(?:\s|$)/
const DescriptionThreshold = 180

function truncateDescription(string) {
  if (!string) return null

  // If the string is below the threshold, use it
  if (string.length <= DescriptionThreshold) return string

  // If the first sentences is below the threshold, use it
  const sentencePieces = string.split(DescriptionRegexp, 2)
  const sentence = sentencePieces.length === 2 && sentencePieces.join("")

  if (sentence && sentence.length <= DescriptionThreshold) return sentence

  // Otherwise truncate the full string and add an ellipsis
  return `${string.substring(0, DescriptionThreshold)}…`
}

function transformOrgCategory(category, categoriesHelp) {
  if (!category) return undefined

  const cat = categoriesHelp.filter(cat => cat.recordId === category)

  const catName = cat[0].name
  return {
    name: catName,
    slug: `/categories/${makeSlug(catName)}`,
  }
}

export function transformOrgCategories(rawCategories) {
  const categories = rawCategories
    .map(transformOrgCategory)
    .sort((a, b) => stringCompare(a.name, b.name))

  if (typeof window === "object") {
    // eslint-disable-next-line no-restricted-globals
    window.categories = categories
    // eslint-disable-next-line no-console
    // console.log({ categories })
  }

  return categories
}

// Accepts a `raw` organization from GraphQL, cleans up the key formatting and
// simplifies data structures.
// Optionally accepts a `userTransform` function to further modify the `out`
// value with `raw` data before returning
export function transformOrganization(raw, categoriesHelp, userTransform = (_, out) => out) {
  const {
    id,
    recordId,
    data: {
      Name,
      About,
      Website,
      Logo,
      Address,
      Tagline,
      Donate_UPI,
      Donate_Bank_Trans,
      Donate_Int_Fund_Trans,
      Donate_Third_Party,
      Is80g,
      How80g,
      Email,
      Phone, 
      Whatsapp,
      Maps,
      Orgtype,
      Categories,
      Name_Contributor,
      Timestamp,
      Email_Contributor,
      youtube,
      facebook,
      instagram,
      twitter,
      telegram,
      LogoURL,
    },
  } = raw

  const showDonateSection = () => {
    if(Donate_UPI) {
      return true
    } else if(Donate_Bank_Trans) {
      return true
    } else if(Donate_Int_Fund_Trans) {
      return true
    } else if(Donate_Third_Party) {
      return true
    } else {
      return false
    }
  }

  return userTransform(raw, {
    id,
    recordId,
    title: Name,
    description: truncateDescription(Tagline || About),
    tagline: Tagline || "",
    about: About || "",
    address: Address,
    location: Maps || "",
    orgType: Orgtype,
    slug: `/organizations/${makeSlug(Name)}`,
    homepage: Website || "",
    logo: Logo || "",
    displayDonateSection: showDonateSection(),
    donate: {
      donateUPI: Donate_UPI || "",
      donateBankTrans: Donate_Bank_Trans || "",
      donateIntFundTrans: Donate_Int_Fund_Trans || "",
      donateThirdParty: Donate_Third_Party || ""
    },
    is80g: Is80g,
    how80g: How80g,
    contact: {
      email: Email,
      phone: Phone, 
      whatsapp: Whatsapp || "",
    },
    contributor: {
      contributorName: Name_Contributor,
      contributorEmail: Email_Contributor || ""
    },
    lastUpdated: Timestamp,
    youtube: youtube || "",
    facebook: facebook || "",
    instagram: instagram || "",
    twitter: twitter || "",
    telegram: telegram || "",
    LogoURL: LogoURL || "",
    categories: Categories?.map(cat => transformOrgCategory(cat, categoriesHelp)) ?? [],
  })  
}

export function transformOrganizations(orgs, userTransform, categoriesHelp) {
  const organizations = orgs.map(org =>
    transformOrganization(org, userTransform, categoriesHelp)
  )

  if (typeof window === "object") {
    // eslint-disable-next-line no-restricted-globals
    window.organizations = organizations
    // eslint-disable-next-line no-console
    // console.log({ organizations })
  }

  return organizations
}

function transformNavCategory(category) {
  if (!category) return undefined

  return {
    id: category.id,
    recordId: category.recordId,
    name: category.data.cat_name,
    count: category.data.Org_Data.length,
    slug: `/categories/${makeSlug(category.data.cat_name)}`,
  }
}

export function transformNavCategories(rawCategories) {
  const categories = rawCategories
    .map(transformNavCategory)
    .sort((a, b) => stringCompare(a.name, b.name))

  if (typeof window === "object") {
    // eslint-disable-next-line no-restricted-globals
    window.categories = categories
    // eslint-disable-next-line no-console
    // console.log({ categories })
  }

  return categories
}

export const transformCapitalTypes = capitalTypes =>
  capitalTypes.map(({ id, data: { Name, Cover, Slug } }) => ({
    id,
    name: Name,
    cover: Cover?.localFiles?.[0]?.childImageSharp?.fluid,
    slug: `/capital/${Slug}`,
  }))
