import React from "react"
import { Link, graphql } from "gatsby"

import { transformCategories } from "../utils/airtable"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TopicCard from "../components/TopicCard"

function ViewAll({ name, href }) {
  return (
    <div className="text-center">
      <Link to={href} className="text-2xl text-blue-600 hover:text-blue-700">
        View All organizations <span className="text-2xl">&rsaquo;</span>
      </Link>
    </div>
  )
}

export default function IndexPage({ data }) {
  const categories = transformCategories(data.allAirtable.nodes)

  return (
    <Layout contentClassName="text-gray-900">
      <SEO />
      <h1 className="font-bold text-2xl md:text-4xl text-center tracking-wide text-gray-800 px-2 sm:px-8 pt-8 md:pt-16">
        Find dharmik organizations and donate
      </h1>
      <p className="text-center text-lg text-gray-800 px-16 sm:px-64 pt-2 md:pt-4">
        DonateForDharma helps you discover <a href="/organizations" class="hover:underline hover:text-red-500">dharmik organizations </a>, connect with 
        them and donate to them
      </p>
      <div id="organizations" className="md:py-6">
        <h2 className="font-bold text-2xl md:text-4xl text-center font-light tracking-wide text-gray-800 mt-6 mb-3">
          View organizations by category
        </h2>
        <div className="p-3 flex flex-wrap max-w-6xl mx-auto">
          {categories.map(cat => (
            <TopicCard category={cat} key={cat.cat_name} />
          ))}
        </div>
        <ViewAll href="/organizations" name="Projects" />
      </div>
      <div id="about" className="max-w-6xl mx-auto lg:flex items-start py-4">
        <div className="text-lg px-6 py-6 flex-1">
          <h2 className="font-bold text-center">
            Add your organization here
          </h2>
          <p className="mt-8 text-center">
            <Link
              to="https://airtable.com/shru2OXtkpN0EkOL0"
              className="inline-block px-4 py-2 border-2 rounded text-white bg-red-500 border-red-500 hover:text-red-500 hover:border-red-500 hover:bg-transparent"
            >
              Submit Org
            </Link>
          </p>
        </div>
        <div className="text-lg px-6 py-6 flex-1">
          <h2 className="font-bold text-center">
          World's largest Hindu Temples Portal
          </h2>
          <p className="mt-8 text-center">
            <Link
              to="https://templesofindia.org" target="_blank"
              className="inline-block px-4 py-2 border-2 rounded text-white bg-red-500 border-red-500 hover:text-red-500 hover:border-red-500 hover:bg-transparent"
            >
             Temples of India
            </Link>
          </p>
        </div>
        <div className="text-lg px-6 py-6 flex-1">
          <h2 className="font-bold text-center">
          Become a Contributor
          </h2>
          <p className="mt-8 text-center">
            <Link
              to="/contribute/"
              className="inline-block px-4 py-2 border-2 rounded text-white bg-red-500 border-red-500 hover:text-red-500 hover:border-red-500 hover:bg-transparent"
            >
              Contribute Now
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql `
query MyQuery {
  allAirtable(filter: {table: {eq: "Categories"}}) {
    nodes {
      data {
        cat_name
        imageURLs
      }
    }
  }
}
`